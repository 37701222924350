import React from 'react';
import BusTracker from './src/BusTracker';
import './src/App.css'; 

const App = () => {
  return (
    <div className="App">
      <BusTracker />
    </div>
  );
};

export default App;
