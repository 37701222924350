import React, { useState, useImperativeHandle, forwardRef } from 'react';

const SearchComponent = forwardRef(({ stops, onStopSelect, lines, onLineSelect, onClose, searchType }, ref) => {
  const [query, setQuery] = useState('');

  useImperativeHandle(ref, () => ({
    setQuery: (value) => setQuery(value)
  }));

  const handleStopSelect = (stop) => {
    onStopSelect(stop);
    setQuery('');
  };

  const handleLineSelect = (line) => {
    onLineSelect(line);
    setQuery('');
  };
  
  const filteredStops = stops.filter(stop => stop.stopName && stop.stopName.toLowerCase().includes(query.toLowerCase()));
  const filteredLines = lines.filter(line => line && line.toString().includes(query));

  return (
    <div className="search-component">
      <div className="search-header">
        <input
          type="text"
          placeholder={`Căutați ${searchType === 'lines' ? 'linie' : 'stație'}`}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <button onClick={onClose} className="close-button">Close</button>
      </div>
      <div className="search-results">
        {searchType === 'stops' && stops.length > 0 && (
          <>
            <h3>Stații</h3>
            {filteredStops.map(stop => (
              <div key={stop.stopID} onClick={() => handleStopSelect(stop)} className="search-result-item">
                {stop.stopName}
              </div>
            ))}
          </>
        )}
        {searchType === 'lines' && lines.length > 0 && (
          <>
            <h3>Linii</h3>
            {filteredLines.map(line => (
              <div key={line} onClick={() => handleLineSelect(line)} className="search-result-item line-item">
                <span className="line-number">{line}</span>
                Linia {line}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
});

export default SearchComponent;
