import React, { useState, useEffect, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './MapComponent.css';
import L from 'leaflet';

const config = {
  API_BASE_URL: "https://ro-sibiu-pia-2.thoreb.com:8443/SignServer/service",
};

const MapComponent = ({ buses, stops }) => {
  const center = [45.79477163646621, 24.137461099289677];
  const [nextVehicles, setNextVehicles] = useState({});
  const [updateNextVehicles, setUpdateNextVehicles] = useState(undefined);
  const [activeStopID, setActiveStopID] = useState(null);

  const busMarkerIcon = (rotationAngle) => L.divIcon({
    className: 'custom-bus-marker',
    iconSize: [35, 35],
    html: `<div style="transform: rotate(${rotationAngle}deg);"></div>`
  });

  const stopMarkerIcon = L.divIcon({
    className: 'custom-stop-marker',
    iconSize: [20, 20],
  });

  const getNextStopName = (currentStopId) => {
    const stop = stops.find(stop => stop.stopID === currentStopId);
    return stop ? stop.stopName : 'N/A';
  };

  const getNextStopCoords = (currentStopId) => {
    const stop = stops.find(stop => stop.stopID === currentStopId);
    return stop ? stop.coords : null;
  };

  const calculateRotationAngle = (currentCoords, nextCoords) => {
    if (!nextCoords) return 0;
    const dx = nextCoords[1] - currentCoords[1];
    const dy = nextCoords[0] - currentCoords[0];
    return (Math.atan2(dy, dx) * 180) / Math.PI;
  };

  const fetchNextVehicles = useCallback((stopID) => {
    fetch(`${config.API_BASE_URL}/getDepartures/list?stop=${stopID}`)
      .then(res => res.json())
      .then((json) => {
        if (json !== undefined && json.length > 0 && json[0]["stop"] === stopID) {
          setNextVehicles(prevState => ({ ...prevState, [stopID]: json }));
        } else {
          setNextVehicles(prevState => ({ ...prevState, [stopID]: undefined }));
        }
      }).catch(function (e) {
      });
  }, []);

  useEffect(() => {
    let interval;
    if (activeStopID !== null) {
      fetchNextVehicles(activeStopID); // Fetch data immediately when the popup opens
      interval = setInterval(() => {
        fetchNextVehicles(activeStopID);
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [activeStopID, fetchNextVehicles]);

  const handlePopupOpen = (stopID) => {
    setActiveStopID(stopID);
  };

  const handlePopupClose = () => {
    setActiveStopID(null);
  };

  const NextVehicles = ({ stopID }) => {
    const vehicles = nextVehicles[stopID];

    if (vehicles !== undefined && (vehicles.length > 0 || vehicles["loading"] !== undefined)) {
      if (vehicles["loading"] !== undefined) {
        return <div>Se încarcă...</div>
      } else {
        return (
          <div className="vehicles-list">
            {vehicles.map(vehicle => (
              <div className="box" key={vehicle.line}>
                <div className="vehicle-id">{vehicle.line !== undefined ? vehicle.line : "-"}</div>
                <div className="info">
                  <div className="text">Sosește în</div>
                  <div className="value">{vehicle.departure !== undefined ? CustomTimeAfter(Number(new Date(vehicle.departure))) : "-"}</div>
                </div>
                <div className="info">
                  <div className="text">Grad de încărcare</div>
                  <div className="value">{vehicle.loadingDegree !== undefined ? ConvertToRoman(vehicle.loadingDegree) : "-"}</div>
                </div>
              </div>
            ))}
          </div>
        )
      }
    }

    return <div>Nici un vehicul</div>
  };

  const CustomTimeAfter = (time) => {
    const now = new Date();
    const diff = time - now;
    const minutes = Math.ceil(diff / 1000 / 60);
    return minutes > 0 ? `${minutes} minute` : 'imediat';
  };

  const ConvertToRoman = (num) => {
    const roman = ["0", "I", "II", "III", "IV", "V"];
    return roman[num] || num;
  };

  return (
    <MapContainer center={center} zoom={13} style={{ height: '100%', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {buses.map(bus => {
        const nextStopCoords = getNextStopCoords(bus.currentStop);
        const rotationAngle = calculateRotationAngle(bus.coords, nextStopCoords);

        return (
          <Marker
            key={bus.vehicleID}
            position={[bus.coords[0], bus.coords[1]]}
            icon={busMarkerIcon(rotationAngle)}
          >
            <Popup>
              <div className="bus-popup-content">
                <div className="bus-line">
                  Linia <span>{bus.line}</span>
                </div>
                <div className="bus-info-box box">
                  <div className="bus-info-item">
                    <div className="text">Sosește în</div>
                    <div className="value">{CustomTimeAfter(new Date(bus.timestamp))}</div>
                  </div>
                  <div className="bus-info-item">
                    <div className="text">Stația</div>
                    <div className="value">{getNextStopName(bus.currentStop)}</div>
                  </div>
                  <div className="bus-info-item">
                    <div className="text">Distanta</div>
                    <div className="value">{bus.distance} m</div>
                  </div>
                </div>
              </div>
            </Popup>
          </Marker>
        );
      })}
      {stops.map(stop => (
        <Marker
          key={stop.stopID}
          position={[stop.coords[0], stop.coords[1]]}
          icon={stopMarkerIcon}
        >
          <Popup
            eventHandlers={{
              add: () => handlePopupOpen(stop.stopID),
              remove: handlePopupClose
            }}
          > 
            <div className="stop-popup-content">
              <div className="stop-name">{stop.stopName}</div>
              <div className="stop-lines stop_description">
                Linii comune: {stop.stopLines.replaceAll('"', '').split(',').map(line => (
                  <span key={line} className="line NumberStyle">{line}</span>
                ))}
              </div>
              <div className="bus-arrivals">
                <div>Ajunge în stație:</div>
                <NextVehicles stopID={stop.stopID} />
              </div>
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default MapComponent;
