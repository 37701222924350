import React, { useEffect, useState, useRef } from 'react';
import MapComponent from './MapComponent';
import SearchComponent from './SearchComponent';
import SchedulePopup from './SchedulePopup';
import axios from 'axios';

const config = {
  API_BASE_URL: "https://ro-sibiu-pia-2.thoreb.com:9090/api",
  CANDY_DATA: {
    INDEX_PARAMS: {
      TIMESTAMP: 0,
      LINE: 1,
      DISTANCE: 2,
      LATITUDE: 3,
      LONGITUDE: 4,
      SPEED: 5,
      CURRENT_STOP: 6,
      VARIANT: 7,
      PUNCTUALITY: 8
    },
    UPDATE_INTERVAL: 1000
  }
};

const BusTracker = () => {
  const [buses, setBuses] = useState([]);
  const [stops, setStops] = useState([]);
  const [filteredBuses, setFilteredBuses] = useState([]);
  const [filteredStops, setFilteredStops] = useState([]);
  const [lines, setLines] = useState([]);
  const [selectedLine, setSelectedLine] = useState(null);
  const [selectedStop, setSelectedStop] = useState(null);
  const [isSearchPopupOpen, setIsSearchPopupOpen] = useState(false);
  const [isSchedulePopupOpen, setIsSchedulePopupOpen] = useState(false);
  const [searchType, setSearchType] = useState('lines'); // 'lines' or 'stops'
  const searchComponentRef = useRef(null);
  const [updateInterval, setUpdateInterval] = useState(config.CANDY_DATA.UPDATE_INTERVAL);

  const fetchBuses = async () => {
    try {
      const res = await axios.get(`${config.API_BASE_URL}/get_all_vehicles`);
      const json = res.data;
      let tempBuses = [];
      let tempLines = new Set();

      Object.keys(json).forEach((key) => {
        const busLine = json[key][config.CANDY_DATA.INDEX_PARAMS.LINE];
        if (
          typeof json[key][config.CANDY_DATA.INDEX_PARAMS.LATITUDE] !== "undefined" &&
          typeof json[key][config.CANDY_DATA.INDEX_PARAMS.LONGITUDE] !== "undefined" &&
          busLine > 0 // Filtrare pentru bus.line > 0
        ) {
          tempBuses.push({
            vehicleID: key,
            timestamp: json[key][config.CANDY_DATA.INDEX_PARAMS.TIMESTAMP],
            line: busLine,
            variant: json[key][config.CANDY_DATA.INDEX_PARAMS.VARIANT],
            coords: [json[key][config.CANDY_DATA.INDEX_PARAMS.LATITUDE], json[key][config.CANDY_DATA.INDEX_PARAMS.LONGITUDE]],
            currentStop: json[key][config.CANDY_DATA.INDEX_PARAMS.CURRENT_STOP],
            distance: json[key][config.CANDY_DATA.INDEX_PARAMS.DISTANCE],
            speed: json[key][config.CANDY_DATA.INDEX_PARAMS.SPEED],
            punctuality: json[key][config.CANDY_DATA.INDEX_PARAMS.PUNCTUALITY]
          });
          tempLines.add(busLine);
        }
      });

      setBuses(tempBuses);
      setLines(Array.from(tempLines));
    } catch (e) {
      console.error(e);
    }
  };

  const fetchStops = async () => {
    try {
      const res = await axios.get(`${config.API_BASE_URL}/get_all_stops`);
      const json = res.data;
      let tempStops = [];

      json.forEach((v) => {
        tempStops.push({
          stopID: v.StopID,
          stopName: v.StopName,
          stopLines: v.StopLines, 
          coords: [v.Latitude, v.Longitude]
        });
      });

      setStops(tempStops);
      setFilteredStops(tempStops);
    } catch (e) {
      console.error(e);
    }
  };

  const handleStopSelect = (stop) => {
    setSelectedStop(stop);
    setFilteredStops([stop]);
    setFilteredBuses(buses.filter(bus => stop.stopLines.includes(bus.line.toString())));
    setIsSearchPopupOpen(false);
  };

  const handleLineSelect = (line) => {
    setSelectedLine(line);
    setFilteredBuses(buses.filter(bus => bus.line === line));
    setFilteredStops(stops.filter(stop => stop.stopLines.includes(line.toString())));
    setIsSearchPopupOpen(false);
  };

  const resetFilters = () => {
    setSelectedLine(null);
    setSelectedStop(null);
    setFilteredBuses(buses);
    setFilteredStops(stops);
  };

  useEffect(() => {
    fetchBuses();
    fetchStops();
    const interval = setInterval(fetchBuses, updateInterval);
    return () => clearInterval(interval);
  }, [updateInterval]);

  useEffect(() => {
    if (selectedLine) {
      setFilteredBuses(buses.filter(bus => bus.line === selectedLine));
      setFilteredStops(stops.filter(stop => stop.stopLines.includes(selectedLine.toString())));
    } else if (selectedStop) {
      setFilteredBuses(buses.filter(bus => selectedStop.stopLines.includes(bus.line.toString())));
      setFilteredStops([selectedStop]);
    } else {
      setFilteredBuses(buses);
      setFilteredStops(stops);
    }
  }, [buses, selectedLine, selectedStop, stops]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: '100vh', width: '100vw' }}>
      <div className="search-menu">
        <button onClick={resetFilters} style={{ display: 'block', width: '100%', padding: '20px', border: 'none', background: 'none' }}>Resetare</button>
        <button onClick={() => { setIsSearchPopupOpen(true); setSearchType('lines'); }} style={{ display: 'block', width: '100%', padding: '20px', border: 'none', background: 'none' }}>Linii</button>
        <button onClick={() => { setIsSearchPopupOpen(true); setSearchType('stops'); }} style={{ display: 'block', width: '100%', padding: '20px', border: 'none', background: 'none' }}>Stații</button>
        <button onClick={() => setIsSchedulePopupOpen(true)} style={{ display: 'block', width: '100%', padding: '20px', border: 'none', background: 'none' }}>Programare</button>
      </div>
      <MapComponent buses={filteredBuses} stops={filteredStops} setUpdateInterval={setUpdateInterval} />
      {isSearchPopupOpen && (
        <div className="search-popup">
          <SearchComponent
            ref={searchComponentRef}
            stops={searchType === 'stops' ? stops : []}
            onStopSelect={handleStopSelect}
            lines={searchType === 'lines' ? lines : []}
            onLineSelect={handleLineSelect}
            onClose={() => setIsSearchPopupOpen(false)}
            searchType={searchType}
          />
        </div>
      )}
      {isSchedulePopupOpen && (
        <SchedulePopup stops={stops} onClose={() => setIsSchedulePopupOpen(false)} />
      )}
    </div>
  );
};

export default BusTracker;
